import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { acquisitionsService } from '../../../services/AcquisitionsService';
import ReactPaginate from 'react-paginate';

class AdminAcquisitions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocumentInvitation: false,
      disabledDocumentTaskBook: false,
      disabledDocumentForms: false,
      disabledDocumentContracts: false,
      disabledDocumentTechnicalDocumentation: false,
      disabledDocumentNecessityReference: false,
      newAcquisition: {
        title: '',
        data_achizitie: '',
        document_invitation: '',
        document_task_book: '',
        document_forms: '',
        document_contracts: '',
        document_technical_documentation: '',
        document_necessity_reference: ''
      },
      existingAcquisitions: [],
      deleteAcquisition: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this._notificationSystem = null;
    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    acquisitionsService
      .getAll()
      .then(jsonResponse => {
        this.setAcquisitionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  setAcquisitionState = response => {
    let existingAcquisitions = { ...this.state.existingAcquisitions };
    let pagination = { ...this.state.pagination };
    existingAcquisitions = response.acquisitions;
    pagination = response.pagination;

    this.setState({ existingAcquisitions });
    this.setState({ pagination });
  };

  handlePageClick = selectedPage => {
    acquisitionsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setAcquisitionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  updateField = event => {
    const field = event.target.name;
    const newAcquisition = this.state.newAcquisition;
    newAcquisition[field] = event.target.value;

    this.setState({
      newAcquisition
    });
  };

  onDropDocumentInvitation = documents => {
    let newAcquisition = { ...this.state.newAcquisition };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAcquisition.document_invitation = documents[0];

      self.setState({ newAcquisition });
      self.setState({ disabledDocumentInvitation: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentInvitation = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition.document_invitation = null;

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentInvitation: false });
  };

  onDropDocumentTaskBook = documents => {
    let newAcquisition = { ...this.state.newAcquisition };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAcquisition.document_task_book = documents[0];

      self.setState({ newAcquisition });
      self.setState({ disabledDocumentTaskBook: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentTaskBook = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition.document_task_book = null;

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentTaskBook: false });
  };

  onDropDocumentForms = documents => {
    let newAcquisition = { ...this.state.newAcquisition };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAcquisition.document_forms = documents[0];

      self.setState({ newAcquisition });
      self.setState({ disabledDocumentForms: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentForms = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition.document_forms = null;

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentForms: false });
  };

  onDropDocumentContracts = documents => {
    let newAcquisition = { ...this.state.newAcquisition };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAcquisition.document_contracts = documents[0];

      self.setState({ newAcquisition });
      self.setState({ disabledDocumentContracts: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentContracts = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition.document_contracts = null;

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentContracts: false });
  };

  onDropDocumentTechnicalDocumentation = documents => {
    let newAcquisition = { ...this.state.newAcquisition };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAcquisition.document_technical_documentation = documents[0];

      self.setState({ newAcquisition });
      self.setState({ disabledDocumentTechnicalDocumentation: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentTechnicalDocumentation = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition.document_technical_documentation = null;

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentTechnicalDocumentation: false });
  };

  onDropDocumentNecessityReference = documents => {
    let newAcquisition = { ...this.state.newAcquisition };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAcquisition.document_necessity_reference = documents[0];

      self.setState({ newAcquisition });
      self.setState({ disabledDocumentNecessityReference: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentNecessityReference = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition.document_necessity_reference = null;

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentNecessityReference: false });
  };

  resetForm = () => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition = {
      title: '',
      data_achizitie: '',
      document_invitation: '',
      document_task_book: '',
      document_forms: '',
      document_contracts: '',
      document_technical_documentation: '',
      document_necessity_reference: ''
    };

    this.setState({ newAcquisition });
    this.setState({ disabledDocumentInvitation: false });
    this.setState({ disabledDocumentTaskBook: false });
    this.setState({ disabledDocumentForms: false });
    this.setState({ disabledDocumentContracts: false });
    this.setState({ disabledDocumentTechnicalDocumentation: false });
    this.setState({ disabledDocumentNecessityReference: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newAcquisition.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/acquisitions/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/acquisitions/${
        this.state.newAcquisition.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingAcquisitions = this.state.existingAcquisitions.slice();

        let index = existingAcquisitions.findIndex(
          existingAcquisitionDocument =>
            existingAcquisitionDocument.id === jsonResponse.id
        );

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Achizitia a fost editata cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Achizitia a fost adaugata cu success.',
            level: 'success',
            position: 'tc'
          });
        }

        if (index !== -1) {
          existingAcquisitions[index] = jsonResponse;
        } else {
          existingAcquisitions.push(jsonResponse);
        }

        this.setState({ existingAcquisitions });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append('acquisition[title]', this.state.newAcquisition.title);

    formData.append(
      'acquisition[data_achizitie]',
      this.state.newAcquisition.data_achizitie
    );

    if (this.state.newAcquisition.document_invitation) {
      if (this.state.newAcquisition.document_invitation.url) {
        formData.append(
          `acquisition[document_invitation]`,
          this.state.newAcquisition.document_invitation.url
        );
      } else {
        formData.append(
          `acquisition[document_invitation]`,
          this.state.newAcquisition.document_invitation,
          this.state.newAcquisition.document_invitation.name
        );
      }
    }

    // if (this.state.newAcquisition.document_invitation) {
    //   let document = this.state.newAcquisition.document_invitation;
    //   formData.append(
    //     `acquisition[document_invitation]`,
    //     document,
    //     document.name
    //   );
    // }

    if (this.state.newAcquisition.document_task_book) {
      if (this.state.newAcquisition.document_task_book.url) {
        formData.append(
          `acquisition[document_task_book]`,
          this.state.newAcquisition.document_task_book.url
        );
      } else {
        formData.append(
          `acquisition[document_task_book]`,
          this.state.newAcquisition.document_task_book,
          this.state.newAcquisition.document_task_book.name
        );
      }
    }

    // if (this.state.newAcquisition.document_task_book) {
    //   let document = this.state.newAcquisition.document_task_book;
    //   formData.append(
    //     `acquisition[document_task_book]`,
    //     document,
    //     document.name
    //   );
    // }

    if (this.state.newAcquisition.document_forms) {
      if (this.state.newAcquisition.document_forms.url) {
        formData.append(
          `acquisition[document_forms]`,
          this.state.newAcquisition.document_forms.url
        );
      } else {
        formData.append(
          `acquisition[document_forms]`,
          this.state.newAcquisition.document_forms,
          this.state.newAcquisition.document_forms.name
        );
      }
    }

    // if (this.state.newAcquisition.document_forms) {
    //   let document = this.state.newAcquisition.document_forms;
    //   formData.append(`acquisition[document_forms]`, document, document.name);
    // }

    if (this.state.newAcquisition.document_contracts) {
      if (this.state.newAcquisition.document_contracts.url) {
        formData.append(
          `acquisition[document_contracts]`,
          this.state.newAcquisition.document_contracts.url
        );
      } else {
        formData.append(
          `acquisition[document_contracts]`,
          this.state.newAcquisition.document_contracts,
          this.state.newAcquisition.document_contracts.name
        );
      }
    }

    // if (this.state.newAcquisition.document_contracts) {
    //   let document = this.state.newAcquisition.document_contracts;
    //   formData.append(
    //     `acquisition[document_contracts]`,
    //     document,
    //     document.name
    //   );
    // }

    if (this.state.newAcquisition.document_technical_documentation) {
      if (this.state.newAcquisition.document_technical_documentation.url) {
        formData.append(
          `acquisition[document_technical_documentation]`,
          this.state.newAcquisition.document_technical_documentation.url
        );
      } else {
        formData.append(
          `acquisition[document_technical_documentation]`,
          this.state.newAcquisition.document_technical_documentation,
          this.state.newAcquisition.document_technical_documentation.name
        );
      }
    }

    // if (this.state.newAcquisition.document_technical_documentation) {
    //   let document = this.state.newAcquisition.document_technical_documentation;
    //   formData.append(
    //     `acquisition[document_technical_documentation]`,
    //     document,
    //     document.name
    //   );
    // }

    if (this.state.newAcquisition.document_necessity_reference) {
      if (this.state.newAcquisition.document_necessity_reference.url) {
        formData.append(
          `acquisition[document_necessity_reference]`,
          this.state.newAcquisition.document_necessity_reference.url
        );
      } else {
        formData.append(
          `acquisition[document_necessity_reference]`,
          this.state.newAcquisition.document_necessity_reference,
          this.state.newAcquisition.document_necessity_reference.name
        );
      }
    }

    // if (this.state.newAcquisition.document_necessity_reference) {
    //   let document = this.state.newAcquisition.document_necessity_reference;
    //   formData.append(
    //     `acquisition[document_necessity_reference]`,
    //     document,
    //     document.name
    //   );
    // }

    return formData;
  }

  setAcquisitionDocumentForEdit = newAcquisitionDoc => {
    let newAcquisition = { ...this.state.newAcquisition };
    newAcquisition = Object.assign({}, newAcquisitionDoc);
    this.setState({ newAcquisition });

    if (
      newAcquisition.document_invitation &&
      newAcquisition.document_invitation.url
    ) {
      this.setState({ disabledDocumentInvitation: true });
    }

    if (
      newAcquisition.document_task_book &&
      newAcquisition.document_task_book.url
    ) {
      this.setState({ disabledDocumentTaskBook: true });
    }

    if (newAcquisition.document_forms && newAcquisition.document_forms.url) {
      this.setState({ disabledDocumentForms: true });
    }

    if (
      newAcquisition.document_contracts &&
      newAcquisition.document_contracts.url
    ) {
      this.setState({ disabledDocumentContracts: true });
    }

    if (
      newAcquisition.document_technical_documentation &&
      newAcquisition.document_technical_documentation.url
    ) {
      this.setState({ disabledDocumentTechnicalDocumentation: true });
    }

    if (
      newAcquisition.document_necessity_reference &&
      newAcquisition.document_necessity_reference.url
    ) {
      this.setState({ disabledDocumentNecessityReference: true });
    }
  };

  openDeleteDialog = id => {
    let deleteAcquisition = { ...this.state.deleteAcquisition };
    deleteAcquisition = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteAcquisition });
  };

  closeDeleteDialog = () => {
    let deleteAcquisition = { ...this.state.deleteAcquisition };
    deleteAcquisition = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteAcquisition });
  };

  handleDelete = () => {
    acquisitionsService
      .deleteAcquisition(this.state.deleteAcquisition.deleteId)
      .then(() => {
        let existingAcquisitions = this.state.existingAcquisitions.slice();
        existingAcquisitions = existingAcquisitions.filter(
          acquisitionDocument =>
            acquisitionDocument.id !== this.state.deleteAcquisition.deleteId
        );
        this.setState({ existingAcquisitions });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Achizitia a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Achizitii publice
          </h3>
          <h1 className="admin-switch-title default-title">Achizitii </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga o achizitie noua</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu achizitie*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newAcquisition.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată achizitie*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="data_achizitie"
                    onChange={this.updateField}
                    value={this.state.newAcquisition.data_achizitie}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document achizitie invitatie de participare:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentInvitation}
                    removeDocument={this.removeDocumentInvitation}
                    disabledDocuments={this.state.disabledDocumentInvitation}
                    document={this.state.newAcquisition.document_invitation}
                    documentFilename={
                      this.state.newAcquisition.document_invitation_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document achizitie caiet de sarcini:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentTaskBook}
                    removeDocument={this.removeDocumentTaskBook}
                    disabledDocuments={this.state.disabledDocumentTaskBook}
                    document={this.state.newAcquisition.document_task_book}
                    documentFilename={
                      this.state.newAcquisition.document_task_book_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document achizitie formulare:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentForms}
                    removeDocument={this.removeDocumentForms}
                    disabledDocuments={this.state.disabledDocumentForms}
                    document={this.state.newAcquisition.document_forms}
                    documentFilename={
                      this.state.newAcquisition.document_forms_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document achizitie modele de contract:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentContracts}
                    removeDocument={this.removeDocumentContracts}
                    disabledDocuments={this.state.disabledDocumentContracts}
                    document={this.state.newAcquisition.document_contracts}
                    documentFilename={
                      this.state.newAcquisition.document_contracts_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document Documentatie Tehnica:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentTechnicalDocumentation}
                    removeDocument={this.removeDocumentTechnicalDocumentation}
                    disabledDocuments={
                      this.state.disabledDocumentTechnicalDocumentation
                    }
                    document={
                      this.state.newAcquisition.document_technical_documentation
                    }
                    documentFilename={
                      this.state.newAcquisition.document_technical_documentation
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document referat necesitate:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentNecessityReference}
                    removeDocument={this.removeDocumentNecessityReference}
                    disabledDocuments={
                      this.state.disabledDocumentNecessityReference
                    }
                    document={
                      this.state.newAcquisition.document_necessity_reference
                    }
                    documentFilename={
                      this.state.newAcquisition.document_necessity_reference
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newAcquisition.id
                        ? 'Editează achizitie'
                        : 'Adaugă achizitie'}
                    </button>
                  </div>
                  {Object.values(this.state.newAcquisition) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingAcquisitions.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Achizitii existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingAcquisitions.map(acquisition => (
                      <div
                        className="existing-items__content-card"
                        key={acquisition.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {acquisition.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setAcquisitionDocumentForEdit(acquisition)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(acquisition.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteAcquisition.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminAcquisitions;
